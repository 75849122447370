import PropTypes from 'prop-types';
import { extendObj } from '../../global-utils/extend-object';
import {
  propToPropMapPropType,
  apiDataPropType,
  dataToCompPropType,
  dataToPropPropType,
  contentDataToPropPropType
} from '../../global-prop-types';
import {
  apiDataToProps,
  mapPropToProp,
  componentItemDataToProps
} from '../../global-utils/map-data-to-props';
import { resolveHideIf, resolveShowIf } from '../componentsUtils';
import useShallowEqualSelector from './useShallowEqualSelector';
import vmFunctions from '../../global-utils/vmFunctions';

const DynamicCompRenderer = (props) => {
  // Destruct props, that should not be passed on to the children.
  // Keep the rest props in the restProps.
  const {
    dataToComp,
    dataToProp,
    propToPropMap,
    componentName,
    hideWhen,
    showIf,
    hideIf,
    parent,
    isDisabled,
    ...restProps
  } = props;
  const { urlParams } = props;

  // Use this properties in this component logic, but also pass them on to children:
  const { apiData, componentItemData, allComps } = props;
  const store = useShallowEqualSelector((state) => state);
  // const urlParams = useParams();

  // @componentConfigProps - each component can have global defaultProps saved by admin
  //  in layoutSettings.componentConfig that overwrite defaultProps
  const componentConfigProps = useShallowEqualSelector(
    (state) => state.appState.settings.componentConfig?.[componentName]
      ?.componentDefaultProps
  );
  const CompItem = allComps?.[componentName];
  if (!CompItem) {
    console.warn('DynamicCompRenderer componentName', componentName, props);
    return null;
  }

  if (isDisabled) return null;
  if (
    hideWhen?.some(
      (el) => el === store.appState.currentBreakpoint || el === 'always'
    )
  ) {
    return null;
  }

  const dataBank = {
    props,
    store,
    urlParams,
    vmFunctions
  };
  /* TODO: Ksawery
   * remove dataToComp after it is gone from admin
   * remove dataToProp after it is gone from GridWithPreview
   * reorganizr the flow!! we go in cyrcles here
   */

  const compProps = extendObj(
    { ...componentConfigProps, ...restProps },
    mapPropToProp(dataBank, propToPropMap),
    { allowExtending: true }
  );

  const allProps = {
    ...compProps,
    ...apiDataToProps(apiData, dataToComp),
    ...componentItemDataToProps(componentItemData, dataToProp)
  };

  dataBank.props = allProps;
  if (!resolveShowIf(showIf, dataBank)) return null;
  if (resolveHideIf(hideIf, dataBank)) return null;

  // TODO: Make loading nice
  return (
    // <Suspense fallback={<Loading />}>
    <CompItem {...allProps} parent={[...parent, componentName]} />
    // </Suspense>
  );
};

DynamicCompRenderer.propTypes = {
  componentName: PropTypes.string.isRequired,

  // data structures:
  apiData: apiDataPropType,
  componentItemData: PropTypes.any,
  contentItemData: PropTypes.shape({}),
  dataToComp: dataToCompPropType,
  dataToProp: dataToPropPropType,
  propToPropMap: propToPropMapPropType,

  contentDataToProp: contentDataToPropPropType,

  // TODO: Create a new PropType
  singleEntry: PropTypes.any,

  hideWhen: PropTypes.array,
  showIf: PropTypes.array,
  hideIf: PropTypes.array,
  parent: PropTypes.array,
  isDisabled: PropTypes.bool,

  allComps: PropTypes.shape({}),
  urlParams: PropTypes.shape({})
};

DynamicCompRenderer.processPropVmTypes = (props) => {
  const {
    dataToComp,
    dataToProp,
    propToPropMap,
    componentName,
    hideWhen,
    showIf,
    hideIf,
    parent,
    isDisabled,
    ...restProps
  } = props;

  return restProps;
};

export default DynamicCompRenderer;
