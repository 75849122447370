import { apiRequest } from '../../global-utils/api-request';
import {
  getAccessToken,
  getUserId
} from '../../global-utils/vmFunctions/loginInfo';
import { checkApiError } from '../../global-utils/vmFunctions/myNetworking/myNetworkingApiUtils';
import { getAppId, getServerAddr } from '../../global-utils/vmFunctions/core';

export const getUrl = () => {
  const url = new URL(`${window.location.protocol}//${getServerAddr()}`);
  url.pathname = '/v1/favourites.json';
  return url;
};

export const apiFavourtesPost = async (itemId, itemType) => {
  const body = {
    access_token: getAccessToken(),
    user_id: getUserId(),
    favouriteable_id: itemId,
    favouriteable_type: itemType,
    include_favouriteable: 1
  };
  return apiRequest({
    method: 'POST',
    url: getUrl(),
    body
  }).then(checkApiError);
};

export const apiFavourtesDelete = async (itemId) => {
  const body = {
    access_token: getAccessToken(),
    user_id: getUserId(),
    id: itemId
  };
  return apiRequest({
    method: 'DELETE',
    url: getUrl(),
    body
  }).then(checkApiError);
};

export const apiFavourtesGet = async () => {
  const body = {
    per_page: 999,
    user_id: getUserId(),
    app_id: getAppId(),
    include_favouriteable: 1,
    include_resources: 1,
    include_contentsessions: 1,
    include_categories: 1
  };
  return apiRequest({
    method: 'GET',
    url: getUrl(),
    body
  }).then(checkApiError);
};
