import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { stylesPropType, vmPropTypes } from '../../global-prop-types';

const replaceElementColor = (element, color) => {
  if (!element || !color) return;
  if (element.hasAttribute && element.hasAttribute('fill')) {
    element.setAttribute('fill', color);
  }
  if (element.hasAttribute && element.hasAttribute('stroke')) {
    element.setAttribute('stroke', color);
  }
  element.childNodes.forEach((child) => {
    replaceElementColor(child, color);
  });
};

const SvgComp = (props) => {
  const { icon, styles, variant } = props;

  const dynamicBeforeInjection = (() => {
    const stylesKeys = Object.keys(styles?.wrapper ?? {}) ?? [];
    if (stylesKeys.length) {
      return {
        beforeInjection: (svg) => {
          const stringStyles = stylesKeys.reduce((acc, key) => {
            const kebabCaseKey = key.replace(/[A-Z]/g, (match) => `-${match.toLocaleLowerCase()}`);
            return `${acc} ${kebabCaseKey}: ${styles.wrapper[key]};`;
          }, '');
          svg.setAttribute('style', stringStyles);
          svg.childNodes.forEach((child) => {
            replaceElementColor(child, styles?.wrapper?.color);
          });
        }
      };
    }
    return {};
  })();

  return (
    <ReactSVG
      src={icon}
      {...dynamicBeforeInjection}
      fallback={() => <img src={icon} alt="svg-icon" />}
      className={`svgComp-wrapper ${variant || ''}`}
      style={styles?.wrapper}
    />
  );
};

SvgComp.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.string,
  styles: stylesPropType
};

SvgComp.defaultProps = {
  variant: '',
  icon: 'https://d2t0unnjxes6lt.cloudfront.net/data/ntw/general-vm/icons/home-active.svg',
  styles: {
    wrapper: {}
  }
};

SvgComp.vmPropTypes = {
  icon: vmPropTypes.string,
  variant: vmPropTypes.className,
  styles: { wrapper: vmPropTypes.styles }
};

export default SvgComp;
