import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsUserLoggedIn } from '../../global-utils';
import { selectConfig, selectData, selectModule } from './selectors';
import { fetchUserComplete } from './actions';

export const useConfig = () => useSelector(selectConfig);
export const useModuleActive = () => !!useSelector(selectModule)?.isActive;

export const useData = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectData) ?? {};
  const isUserLoggedIn = useIsUserLoggedIn();

  useEffect(() => {
    const { apiStatus } = userData;
    if (!isUserLoggedIn) return;
    if (apiStatus) return; // this means, the call is either on it's way or was aready done.

    dispatch(fetchUserComplete());
  }, [isUserLoggedIn]);

  return userData;
};
